<template>
  <div class="policy_container">
    <!--协议弹出框-采购商协议-->
    <div class="agreement_box" v-if="policy">
      <div class="agreement_header">
        <div class="agreement_title">隐私政策</div>
        <div class="close_btn" @click="closeAgreement">×</div>
      </div>
      <div class="agreement_body">
        <div class="home_guide">
          <p v-html="htmlText"></p>
        </div>
      </div>
      <div class="footer">
        <div class="agree_close" @click="closeAgreement">确认关闭</div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    policyText
  } from './data/policy'
  export default {
    name: 'WorkspaceJsonPolicy',

    data() {
      return {
        policy: false,
        htmlText: ''

      };
    },

    mounted() {

    },
    created() {
      this.htmlText = policyText
      console.log(this.policyText);

      this.policy = true
    },

    methods: {
      // 点击关闭  隐私政策
      closeAgreement() {
        this.$router.push('/index')

      }

    },
  };

</script>

<style lang="scss" scoped>
  .policy_container {
    background: url(".././assets/bgc.png") no-repeat center center / auto 100% rgb(245, 245, 245);
    width: 100%;
    height: 100%;
    min-width: 960px;
    position: fixed;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 30px;
    text-align: left;
    opacity: .9;

    .agreement_box {
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background-color: #FFFFFF;
      box-sizing: border-box;
      width: 750px;
      padding: 10px 10px 10px 10px;
      z-index: 999 !important;

      .agreement_header {
        height: 40px;
        line-height: 40px;
        font-size: 17px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #ebe2e2;

        .close_btn {
          width: 30px;
          height: 30px;
          font-size: 30px;
          color: #888888;
          cursor: pointer;
        }

      }

      .agreement_body {
        height: 500px;
        padding: 30px 40px;
        overflow-y: scroll;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: left;
      }

      .footer {
        height: 50px;
        box-sizing: border-box;
        border-top: 1px solid #ebe2e2;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .agree_close {
          width: 120px;
          height: 35px;
          background-color: red;
          text-align: center;
          line-height: 35px;
          font-size: 14px;
          color: #FFFFFF;
          margin: 12px 0;
          cursor: pointer;
        }
      }
    }
  }

</style>
